import { defineStore } from 'pinia'
import { apiPost, apiGet } from '~/services/api'
import { deleteUnavaliable } from '~/helpers/photos/deleteUnavaliable'
import { getUserAvatar } from '~/helpers/Parser/Avatars/getUserAvatar'

interface CurrentFilters {
  [key: string]: string | undefined
}

interface shortViewData {
  address_formatted: string
  agent: {
    avatar: string
    employment_status: string
    fullname: string
    id: number
    phone: string
  } | null
  created_at: string
  id: number
  photos: Array<any>
  price: string
  summary: string[]
  type_description: string
  ad_phone: string
  ad_source: string
  ad_url: string
  source: string
  ad_avatar?: string
}

interface MapParams {
  zoom: number
  center_lat: number
  center_lon: number
}

const config = useRuntimeConfig()

export const YmapStore = defineStore('Ymap', {
  state: () => ({
    Ymap: null,
    searchObjData: [],
    searchObjDataList: [] as any,
    shortInfoData: [] as shortViewData[],
    searchObjLoader: false,
    updateFiltersTrigger: false,
    searchByStringTrigger: false,
    searchString_lat: 0,
    searchString_lon: 0,
    isShortInfoOpen: false,
    shortViewLoader: true,
    photoLoader: true,
    areaToSearch: [] as number[][],
    mapBounds: [] as number[][],
    mapPolygon: null,
    currentZoom: 9,
    mapParamsBase: {} as MapParams,
    cashedClusters: [] as any[],
    cashedDots: [] as any[],
    currentListPage: 1,
    totalListPages: 0,
    totalListObjects: 0,
    showSuggestions: false,
    currentLocation: '',
    scriptYmap: document.createElement('script'),
    geoApiCity: {
      GeocoderMetaData: {
        AddressDetails: {
          Country: {
            AdministrativeArea: {
              Locality: {
                LocalityName: null,
              },
            },
          },
        },
      },
    },
  }),

  actions: {
    async fetchSearchCluster(args: { mapParams: MapParams; filters?: CurrentFilters }) {
      if (this.checkIsFilters(args.filters, 'object_type') !== 'flat') {
        delete args.filters['rooms']
      }
      if (args.filters) {
        args.filters['price_from'] = this.checkIsFilters(args.filters, 'price_from') ?? ''
        args.filters['price_to'] = this.checkIsFilters(args.filters, 'price_to') ?? ''
      }
      try {
        const { data } = await apiPost({
          url: `api/object/search-on-map-clustered`,
          query: { ...args.filters, ...args.mapParams },
          body: { bound_box: this.mapBounds },
        })
        if (data.value && data.value.data) {
          this.searchObjData = data.value.data.clusters
        }
      } catch (e) {
        console.log(e)
        this.updateFiltersTrigger = false
      } finally {
      }
    },
    async fetchSearchObjet(filters?: CurrentFilters) {
      if (this.checkIsFilters(filters, 'object_type') !== 'flat') {
        delete filters['rooms']
      }
      if (filters) {
        filters['price_from'] = this.checkIsFilters(filters, 'price_from') ?? ''
        filters['price_to'] = this.checkIsFilters(filters, 'price_to') ?? ''
      }

      try {
        const { data } = await apiPost({
          url: `api/object/search-on-map`,
          query: filters,
          body: { bound_box: this.mapBounds },
        })
        if (data.value && data.value.data) {
          this.searchObjData = data.value.data
        }
      } catch (e) {
        console.log(e)
        this.updateFiltersTrigger = false
      }
    },

    async fetchShortInfo(ids: number[]) {
      this.shortViewLoader = true
      this.photoLoader = true
      try {
        const query = { ids: ids }
        const { data }: any = await apiGet({ url: `api/object/view-short-info`, query })
        if (data.value && data.value.data) {
          this.shortInfoData = data.value.data
          this.shortInfoData.forEach((item) => {
            item.ad_avatar = getUserAvatar(item)
          })
        }
      } catch (e) {
        console.log(e)
        this.shortViewLoader = true
      } finally {
        this.shortViewLoader = false
        this.shortInfoData = (await deleteUnavaliable(this.shortInfoData)) as shortViewData[]
        this.photoLoader = false
      }
    },
    async fetchSearchObjetList(args: { polygon?: number[][]; filters?: CurrentFilters }) {
      this.photoLoader = true
      if (args.filters && this.checkIsFilters(args.filters, 'object_type') !== 'flat') {
        delete args.filters['rooms']
      }
      if (args.filters) {
        args.filters['price_from'] = this.checkIsFilters(args.filters, 'price_from') ?? ''
        args.filters['price_to'] = this.checkIsFilters(args.filters, 'price_to') ?? ''
      }

      this.searchObjLoader = true
      const searchArea = args.polygon && args.polygon.length ? args.polygon : this.mapBounds
      try {
        const { data } = await apiPost({
          url: `api/object/search-on-tiles`,
          query: args.filters,
          body: { bound_box: searchArea },
        })
        if (data.value && data.value.data) {
          this.totalListPages = data.value.meta.last_page
          this.totalListObjects = data.value.meta.total
          this.searchObjDataList = data.value.data
          this.searchObjDataList.forEach((item: any) => {
            item.ad_avatar = getUserAvatar(item)
            item.photoLoader = true
          })
        }
      } catch (e) {
        console.log(e)
        this.searchObjLoader = true
        this.updateFiltersTrigger = false
      } finally {
        this.searchObjLoader = false
        this.searchObjDataList = await deleteUnavaliable(this.searchObjDataList)
        this.searchObjDataList.forEach((item: any) => {
          item.photoLoader = false
        })
      }
    },
    async fetchSearchObjetListPagination(args: { polygon: number[][]; filters?: CurrentFilters }) {
      this.currentListPage = this.currentListPage + 1
      if (this.currentListPage > this.totalListPages) return
      this.photoLoader = true
      let newDataPagination = null
      if (args.filters && this.checkIsFilters(args.filters, 'object_type') == 'flat') {
        delete args.filters['rooms']
      }

      if (args.filters) {
        args.filters['price_from'] = this.checkIsFilters(args.filters, 'price_from') ?? ''
        args.filters['price_to'] = this.checkIsFilters(args.filters, 'price_to') ?? ''
      }
      const searchArea = args.polygon && args.polygon.length ? args.polygon : this.mapBounds
      try {
        const { data } = await apiPost({
          url: `api/object/search-on-tiles`,
          query: { ...args.filters, page: this.currentListPage },
          body: { bound_box: searchArea },
        })
        if (data.value && data.value.data) {
          newDataPagination = data.value.data
          newDataPagination.forEach((item: any) => {
            item.ad_avatar = getUserAvatar(item)
            item.photoLoader = true
          })
          const filtered = await deleteUnavaliable(newDataPagination)
          this.searchObjDataList = [...this.searchObjDataList, ...filtered]
          this.searchObjDataList.forEach((item: any) => {
            item.photoLoader = false
          })
        }
      } catch (e) {
        console.log(e)
        this.updateFiltersTrigger = false
      } finally {
      }
    },
    async loadYmap() {
      this.scriptYmap.src = `https://api-maps.yandex.ru/2.1/?apikey=${config.public.env.Y_MAP_API_KEY}&lang=ru_RU`
      this.scriptYmap.type = 'text/javascript'
      this.scriptYmap.async = true
      this.scriptYmap.defer = true
      document.head.appendChild(this.scriptYmap)
    },
    checkIsFilters(obj: Object | undefined, index: string) {
      if (obj && obj[index]) {
        return obj[index].toString().replace(/\s/g, '')
      } else {
        return null
      }
    },
  },
})
